import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { searchCustomer, adjustVideoPTSessions, getNotes, addPhysioToCustomer, removePhysioToCustomer, deleteNote, deleteCustomer, byPassSubscription } from "../api/customer"
import { fetchPhysios } from "../api/admin"
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Brush
} from "recharts"
import dayjs from "dayjs"
import Checkbox from '@mui/material/Checkbox';
import TextEditor from "../components/TextEditor";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ReactJson from 'react-json-view';
import { useParams, useLocation, useNavigate } from "react-router-dom"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  LinearProgress,
  TextField
} from "@mui/material"
import { useAuth } from '../context/authContext'
import { font } from '../themes/fonts'
import { v4 as uuidv4 } from 'uuid';
import Modal from '@mui/material/Modal';

interface KeyStats {
  id: number | null
  protocol_type_id: number | null
  injury_date: string | null
  surgery_date: string | null
  current_progress_stage_id: number | null
  currentWeek: number | null
  stepsComplete: number
}

interface LocationPhysio {
  assignedPhysio: null | number
}

interface Protocol {
  protocolName: string
  protocolTypeName: string
}

interface AverageData {
  adherence_overall: number;
  adherence_last_7_days: number;
  flexion_overall: number;
  flexion_last_7_days: number;
  extension_overall: number;
  extension_last_7_days: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Customers({ ...props }) {

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [customer, setCustomer] = useState<any>("")
  const [result, setResult] = useState<any>("")
  const [error, setError] = useState<string>("")
  const [userMeasurements, setUserMeasurements] = useState<any>([])
  const [userAchievements, setUserAchievements] = useState<any>("")
  const [videoPTSessions, setVideoPTSessions] = useState<number>(0)
  const [adherence, setAdherence] = useState<any>([])
  const [notes, setNotes] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [subscriptionTransactions, setSubscriptionTransactions] = useState([])
  const [revenuecatSubscriptionTransactions, setRevenuecatSubscriptionTransactions] = useState([])
  const [firstDateOfSubscription, setFirstDateOfSubscription] = useState(null)
  const [activeSubscriptions, setActiveSubscription] = useState<any>(null)
  const [activeRevenuecatSubscriptions, setActiveRevenuecatSubscription] = useState<any>(null)
  const [stageList, setCurrentStageList] = useState<any>([])
  const [byPassSubscriptions, setByPassSubscriptions] = useState<any>({})
  const [metadata, setMetadata] = useState<any>({})
  const [averageData, setAverageData] = useState<AverageData>()
  
  const [openStage, setOpenStage] = useState<boolean>(false);
  const [open, setOpen] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [firstStage, setFirstStage] = useState<string>("N/A");
  const [currentStage, setCurrentStage] = useState<string>("N/A");
  const [allTracks, setAllTracks] = useState<any>([]);
  const [openTrack, setOpenTrack] = useState<boolean>(false);

  // const [subApiDataStoreInState, setSubApiDataStoreInState] = useState<any>({})
  const [keyStats, setKeyStats] = useState<KeyStats>({
    id: null,
    protocol_type_id: null,
    injury_date: null,
    surgery_date: null,
    current_progress_stage_id: null,
    currentWeek: null,
    stepsComplete: 0
  })
  const [assignedAsPatient, setAssignedAsPatient] = useState<number | null>(null)
  const [protocol, setProtocol] = useState<Protocol>({ protocolName: 'unknown', protocolTypeName: 'unknown' })
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
  const [isByPassSubModal, setIsByPassSubModal] = useState<boolean>(false)
  const [adminPassword, setAdminPassword] = useState<string>('')

  const { emailParam, id } = useParams()
  const { loggedInUser } = useAuth()
  const navigate = useNavigate()
  // const location = useLocation()
  // const { assignedPhysio } = location.state as any

  useEffect(() => {
    const handleSearch = async (customerId: number) => {
      setIsLoading(true)
      const result = await searchCustomer(customerId)
      if (!!result.customer) {
        setResult(result.customer)
        setAssignedAsPatient(result.customer.assigned_physio)
        setVideoPTSessions(result.customer.video_pt_sessions)
      } else {
        setResult("")
        setError(`No customer found with the customerId: ${customerId}`)
      }

      if (result.measurements.length > 0) {
        setUserMeasurements({
          flexion: result.measurements
            .filter((measurement: any) => measurement.measurement_type === 0)
            .reverse(),
          extension: result.measurements
            .filter((measurement: any) => measurement.measurement_type === 1)
            .reverse(),
        })
      }

      if (!!result.track) {
        setKeyStats({
          id: result.track.id,
          protocol_type_id: result.track.protocol_type_id,
          injury_date: result.track?.injury_date ? dayjs(result.track.injury_date).format('MMM DD, YYYY') : null,
          surgery_date: result.track?.surgery_date ? dayjs(result.track.surgery_date).format('MMM DD, YYYY') : null,
          current_progress_stage_id: result.track.current_progress_stage_id,
          currentWeek: result.week.length > 0 ? result.week[0].week_number : 0,
          stepsComplete: result.steps.flat().filter((step: any) => step.todo_completion_percentage === '1.00').length
        })
      }

      setProtocol({
        protocolName: result.track?.protocol_name || '-',
        protocolTypeName: result.track?.protocol_type_name || '-'
      })

      if (!!result.bypassSubscription) {

        setChecked(result.bypassSubscription.is_enabled)

        // console.log('bypass subscription', result.bypassSubscription)
        setByPassSubscriptions(result.bypassSubscription ? result.bypassSubscription : null)

        setStartDate(result.bypassSubscription?.start_time ? new Date(result.bypassSubscription?.start_time) : null)
        setEndDate(result.bypassSubscription?.end_time ? new Date(result.bypassSubscription?.end_time) : null)
      }

      if (!!result.steps.length) {
        setAdherence(result.steps.flat().map((step: any) => ({ ...step, todo_completion_percentage: Number(step.todo_completion_percentage) * 100 })).sort((a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()))
      }

      if (!!result?.track) setUserAchievements(result.track);

      if (!!result?.firstDateOfSubscription) setFirstDateOfSubscription(result?.firstDateOfSubscription)
      if (!!result?.subscriptions?.length) setSubscriptions(result.subscriptions);
      if (!!result?.subscriptionTransactions?.length) setSubscriptionTransactions(result.subscriptionTransactions);
      if (!!result?.revenuecatSubscriptionTransactions?.length) setRevenuecatSubscriptionTransactions(result.revenuecatSubscriptionTransactions);

      setAverageData({
        adherence_overall: !!result?.adherencesAverage?.average_adherence_overall ? Number(result?.adherencesAverage?.average_adherence_overall) : 0,
        adherence_last_7_days: !!result?.adherencesAverage?.average_adherence_last_7_days ? Number(result?.adherencesAverage?.average_adherence_last_7_days) : 0,
        flexion_overall: !!result?.measurementsAverage?.average_type_flexion_overall ? Number(result?.measurementsAverage?.average_type_flexion_overall) : 0,
        flexion_last_7_days: !!result?.measurementsAverage?.average_type_flexion_last_7_days ? Number(result?.measurementsAverage?.average_type_flexion_last_7_days) : 0,
        extension_overall: !!result?.measurementsAverage?.average_type_extension_overall ? Number(result?.measurementsAverage?.average_type_extension_overall) : 0,
        extension_last_7_days: !!result?.measurementsAverage?.average_type_extension_last_7_days ? Number(result?.measurementsAverage?.average_type_extension_last_7_days) : 0
      });

      if (!!result.activeSubscription) setActiveSubscription(result.activeSubscription);

      if (!!result.activeRevenuecatSubscription) setActiveRevenuecatSubscription(result.activeRevenuecatSubscription);

      if (!!result.foundStageList) setCurrentStageList(result.foundStageList);

      if (!!result?.metadata) setMetadata(result?.metadata || null);

      if (!!result?.firstStage) setFirstStage(result?.firstStage || 'N/A');

      if (!!result?.currentStage) setCurrentStage(result?.currentStage || 'N/A');

      if (!!result?.allTracks) setAllTracks(result?.allTracks || []);

      setIsLoading(false)
    }


    if (id) handleSearch(Number(id))

    let noteData: any
    const notesFetch = async (id: string) => {
      if (id) {
        noteData = await getNotes(parseInt(id))
      }
      setNotes(noteData.notes)
    }
    if (id) notesFetch(id)

  }, [])
  // console.log(byPassSubscriptions, 'byPassSubscriptions')
  // TODO --- move physios to a context
  // useEffect(() => {

  //   const getPhysios = async () => {
  //     const data = await fetchPhysios()
  //     setPhysios(data.physios)
  //   }
  //   getPhysios()
  // }, [])

  // const handlePhysioChange = () => {
  //   console.log('handle change running')
  // }

  const handleAddPhysioToCustomer = async () => {
    if (loggedInUser) {
      const data = await addPhysioToCustomer(loggedInUser.id, Number(result.id))
      if (data.success) {
        setAssignedAsPatient(loggedInUser.id)
      }
    }
  }

  const handleRemovePhysioToCustomer = async () => {
    if (loggedInUser) {
      const data = await removePhysioToCustomer(loggedInUser.id, Number(result.id))
      if (data.success) {
        setAssignedAsPatient(null)
      }
    }
  }

  const handleDeleteNote = async (id: string) => {
    console.log('running handleDeleteNote for id of:', id)
    const data = await deleteNote(id)
    if (data.success) {
      setNotes(notes.filter((note: any) => note.id !== id))
    }
  }

  const handleClickStageCollapse = () => {
    setOpenStage(!openStage);
  };

  const handleClickTrackCollapse = () => {
    setOpenTrack(!openTrack);
  };

  const getKeyStatHeading = (heading: string) => {
    switch (heading) {
      case "injury_date":
        return "Injury Date"
      case "surgery_date":
        return "Surgery Date"
      case "currentWeek":
        return "Current Week"
      case "stepsComplete":
        return "Days of Exercise"
      default:
        return null
    }
  }

  const handleClickOpen = (item: any) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const showDeleteModal = () => {
    setIsDeleteModal(true)
  }
  const hideDeleteModal = () => {
    setIsDeleteModal(false)
  }

  const showByPassSubModal = () => {
    setIsByPassSubModal(true)
  }
  const hideByPassSubModal = () => {
    setIsByPassSubModal(false)
    // setStartDate(null)
    // setEndDate(null)
  }

  const [checked, setChecked] = React.useState(false);
  // console.log(checked, 'checked check')
  const [startDate, setStartDate] = useState<Date | null>(new Date()); // Specify the type as Date | null
  const [endDate, setEndDate] = useState<Date | null>(new Date()); // Specify the type as Date | null
  const getStartData = dayjs(byPassSubscriptions.start_time ? new Date(byPassSubscriptions.start_time) : 'Start At').format('DD/MM/YYYY');
  const getEndData = dayjs(byPassSubscriptions.end_time ? new Date(byPassSubscriptions.end_time) : 'End At').format('DD/MM/YYYY');
  // console.log(getStartData, 'getStartData')
  const formattedStartDate = dayjs(getStartData, 'YYYY-MM-DD').isValid() ? getStartData : 'Start At';
  const formattedEndDate = dayjs(getEndData, 'YYYY-MM-DD').isValid() ? getEndData : 'End At';

  // console.log(result.id, 'result.id');

  const saveByPassSubModal = async () => {
    const res = await byPassSubscription(result.id, checked, startDate, endDate)
    // setSubApiDataStoreInState(res);
    setByPassSubscriptions(res.bypassSubscription)
    // setStartDate(null)
    // setEndDate(null)
    setIsByPassSubModal(false)
    // console.log(res.bypassSubscription, 'res res res res res resresresresresresresresresresresresresresres')
    // setIsByPassSubModal(false)
  }

  // console.log(subApiDataStoreInState , 'subApiDataStoreInState')

  const handleDateChange = (date: Date | null) => {
    // console.log(date, 'date :::::::::::::')
    setStartDate(date); // No type error because `date` is of type Date | null
  };
  const handleEndDateChange = (date: Date | null) => {
    // console.log(date, 'End date :::::::::::::')
    setEndDate(date); // No type error because `date` is of type Date | null
  };

  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    // console.log(event.target.checked, 'event.target.checked')
    setChecked(event.target.checked);
  };


  const handleConfirmDeletion = async () => {
    const res = await deleteCustomer(adminPassword, result.id, loggedInUser ? loggedInUser.id : 0)
    if (res.success === true) {
      navigate(-1)
    }
  }

  // console.log({ adherence })

  return (
    <>
      {isLoading
        ?
        <LinearProgress />
        :
        <>

          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xl"
            fullWidth
          >
            <DialogTitle>Json Data</DialogTitle>
            <DialogContent>
              {selectedItem && (
                <Box>
                  <Typography variant="caption" color="textSecondary" fontSize={12}>
                    <ReactJson src={selectedItem} displayDataTypes={false} enableClipboard={false} collapsed={1} />
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Modal
              open={isDeleteModal}
              onClose={hideDeleteModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Delete {result.name} ?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  This will delete the user account and cannot be undone. To confirm the deletion of this account, enter the admin password.
                </Typography>
                <TextField id="outlined-basic" label="Password" type="password" variant="outlined" onChange={(e) => setAdminPassword(e.target.value)} />
                <Button onClick={() => handleConfirmDeletion()}>CONFIRM DELETION</Button>
              </Box>
          </Modal>

          <Modal
            open={isByPassSubModal}
            onClose={hideByPassSubModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Edit: Bypass Subscription</Typography>
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <Typography style={{ marginTop: 8 }}>Enable</Typography>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>

              {checked ? (
                <div>
                  <div style={{ fontSize: 13 }}>
                    <Typography>Start At</Typography>
                    <DatePicker
                      showIcon
                      placeholderText={formattedStartDate}
                      selected={startDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date)} // Pass the handler function to handle date changes
                    />
                  </div>
                  <div style={{ marginBottom: 15, marginTop: 10 }}>
                    <Typography style={{ fontSize: 15 }}>End At</Typography>
                    <DatePicker
                      showIcon
                      placeholderText={formattedEndDate}
                      selected={endDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => handleEndDateChange(date)} // Pass the handler function to handle date changes
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              <div>
                <Button variant="contained" color="primary" style={{ marginRight: 15 }}
                  onClick={() => saveByPassSubModal()}>Save</Button>
                <Button variant="contained" color="primary" onClick={() => hideByPassSubModal()}>cancel</Button>
              </div>

            </Box>
          </Modal>

          <Grid container spacing={2}>

            {result ? (
              <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '50px' }}>
                <Typography >
                  <strong>User Information:</strong> 
                  <IconButton onClick={() => handleClickOpen({...result, metadata})} size="small">
                    <InfoIcon />
                  </IconButton>
                </Typography>
                <Typography><strong>Id:</strong> {result.id}</Typography>
                <Typography><strong>Sub:</strong> {result.user_id}</Typography>
                <Typography><strong>Name:</strong> {result.name}</Typography>
                <Typography><strong>Email:</strong> {result.email}</Typography>
                  <Box>
                    <Typography><strong>Age:</strong> {result.age ? result.age : 'unknown'}</Typography>
                    <Typography><strong>Birthday:</strong> {result.birthdate ? dayjs(result.birthdate).format('MMM DD, YYYY') : 'N/A'}</Typography>
                    <Typography><strong>Address:</strong> {result.address ? result.address : 'unknown'}</Typography>
                    <Typography><strong>City:</strong> {metadata.city ? metadata.city : 'unknown'}</Typography>
                    <Typography><strong>Country:</strong> {metadata.country ? metadata.country : 'unknown'}</Typography>
                    <Typography><strong>Gender:</strong> {result.gender ? result.gender : 'unknown'}</Typography>
                    <Typography><strong>Platform:</strong> {!!metadata?.devicePlatform ? metadata.devicePlatform : 'unknown'}</Typography>
                    <Typography><strong>Joined At:</strong> {result.created_at ? dayjs(result.created_at).format('MMM DD, YYYY (HH:mm)') : 'N/A'}</Typography>
                    <Typography><strong>1<sup>st</sup> Subscription Date:</strong> {firstDateOfSubscription ? dayjs(firstDateOfSubscription).format('MMM DD, YYYY (HH:mm)') : 'N/A'}</Typography>
                    <Typography><strong>Subscriber Type:</strong> {!!metadata?.subscriberType ? metadata.subscriberType : 'unknown'}</Typography>
                    <Typography>
                      <strong>Active Subscription: </strong> 
                      {(!!activeSubscriptions || !!activeRevenuecatSubscriptions) ? 'Yes' : 'unknown'}
                      {activeSubscriptions ? (
                        <IconButton onClick={() => handleClickOpen(activeSubscriptions)} size="small">
                          <InfoIcon />
                        </IconButton>
                      ) : null}

                      {activeRevenuecatSubscriptions ? (
                        <IconButton onClick={() => handleClickOpen(activeRevenuecatSubscriptions)} size="small">
                          <InfoIcon />
                        </IconButton>
                      ) : null}
                    </Typography>
                  </Box>
              </Grid>
            ) : (
              <div>{error}</div>
            )}

            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '10px' }}>
              <Typography style={{ fontWeight: 800 }}>Current Protocol:</Typography>
              <Typography><strong>Protocol Name:</strong> {protocol.protocolName}</Typography>
              <Typography><strong>Sub-Protocol Name:</strong> {protocol.protocolTypeName}</Typography>
              
              <Typography >
                <i>See all protocols</i>
                <IconButton onClick={handleClickTrackCollapse} size="small">
                  {openTrack ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Typography>
              <Collapse in={openTrack} timeout="auto" unmountOnExit>
                <List>
                  { allTracks && allTracks.length > 0 ? (
                    allTracks.map((track: any, index: number) => (
                      <React.Fragment key={uuidv4()}>
                        <ListItem 
                          alignItems="flex-start"
                          sx={{
                            backgroundColor: !!track?.is_current_track ? '#AAD400' : 'inherit',
                            borderRadius: '5px',
                          }}
                          >
                          <ListItemText color="textPrimary"
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                </Typography>
                                <Box component="span" display="block">
                                  <strong>Protocol:</strong> {track.protocol_name}
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Sub Protocol:</strong> {track.protocol_type_name}
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Created At:</strong> { track.created_at ? dayjs(track.created_at).format('MMM DD, YYYY (HH:mm)') : 'N/A' }
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Injury_date date:</strong> { track.injury_date ? dayjs(track.injury_date).format('MMM DD, YYYY') : 'N/A' }
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Surgery date:</strong> { track.surgery_date ? dayjs(track.surgery_date).format('MMM DD, YYYY') : 'N/A' }
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Completed step count:</strong> { track?.steps_complete_count }
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Step streak count:</strong> { track?.step_streak_count }
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Is Current:</strong> {!!track?.is_current_track ? 'Yes' : 'No'}
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Is Archived:</strong> {!!track?.is_archive ? 'Yes' : 'No'}
                                </Box>

                                <Box component="span" display="block">
                                  <strong>Is Deleted:</strong> {!!track?.is_deleted ? 'Yes' : 'No'}
                                </Box>

                                <Box component="span" display="block">
                                  <IconButton onClick={() => handleClickOpen(track)} size="small">
                                    <InfoIcon />
                                  </IconButton>
                                </Box>
                              </React.Fragment>
                            } 
                          />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography>
                      This user has no set protocol
                    </Typography>
                  )
                  }
                </List>
              </Collapse>

              <Divider component="div" style={{ marginTop: '30px' }} />

              <Typography style={{ fontWeight: 800, marginTop: '30px' }}>
                PT points:
              </Typography>

              {result.video_pt_sessions && result.video_pt_sessions > 0 ? (
                <div>
                  <Typography>The user has {result.video_pt_sessions} video PT sessions</Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <input
                      type="number"
                      min={0}
                      value={videoPTSessions}
                      onChange={e => setVideoPTSessions(parseInt(e.target.value))}
                      style={{ marginRight: '20px' }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        adjustVideoPTSessions(videoPTSessions, Number(result.id))
                      }
                    >
                      SET
                    </Button>
                  </div>
                </div>
              ) : (
                <div>The user has no video PT sessions</div>
              )}

            </Grid>

            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '30px' }}>
              {/* <Typography style={{ fontWeight: 800 }}>Protocol</Typography> */}
              <Typography style={{ fontWeight: 800 }}>
                Bypass Subscription:
              </Typography>
              {byPassSubscriptions.is_enabled ? (
                <div>
                  <Typography key={uuidv4()}>
                    <Box component="span" display="block">
                      Bypass subscription is <strong>Enabled</strong>
                    </Box>
                    <Box component="span" display="block">
                      From <span><strong>{dayjs(new Date(byPassSubscriptions.start_time)).format("MMM DD, YYYY")}</strong></span>
                    </Box>

                    <Box component="span" display="block">
                      To <span><strong>{dayjs(new Date(byPassSubscriptions.end_time)).format("MMM DD, YYYY")}</strong></span>
                    </Box>
                  </Typography>
                </div>
              ) : (
                <Typography>
                  Bypass subscription is <strong>Disabled</strong>.
                </Typography>
              )
              }
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px' }}>
                <Button onClick={() => showByPassSubModal()} size="large" variant="contained" color="primary">Edit</Button>
              </div>

              <Divider component="div" />

              <Typography style={{ fontWeight: 800, marginTop: '20px' }}>
                Physio Assignment:
              </Typography>

              {
                !assignedAsPatient ?
                  <div onClick={handleAddPhysioToCustomer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px' }}>
                    <Button size="large" variant="contained" color="primary">Assign as my patient</Button>
                  </div>
                  :
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px' }}>
                    <Typography>{result.name} is currently your patient.</Typography>
                    <Button onClick={handleRemovePhysioToCustomer} size="large" variant="contained" color="warning">Remove from patient list</Button>
                  </div>
              }

            </Grid>

          </Grid>
          
          <Grid style={{ marginTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} direction='row' wrap="wrap" columns={6} lg={6} md={4}>
            {Object.keys(keyStats).map((stat: string, index: number, array: string[]) => (
              (stat === 'id' || stat === 'current_progress_stage_id' || stat === 'protocol_type_id') ? null :
                <Card key={uuidv4()} variant="outlined" style={{ minWidth: '200px' }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {keyStats[stat as keyof typeof keyStats] ? keyStats[stat as keyof typeof keyStats] : 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {getKeyStatHeading(stat)}
                    </Typography>
                  </CardContent>
                </Card>
            ))}
            <Card variant="outlined" style={{ maxWidth: '200px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  { !!keyStats?.surgery_date ? dayjs().diff(keyStats.surgery_date, 'days') : 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Days Since Surgery
                </Typography>
              </CardContent>
            </Card>
            <Card variant="outlined" style={{ maxWidth: '200px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  { !!keyStats?.injury_date ? dayjs().diff(keyStats.injury_date, 'days') : 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Days Since Injury
                </Typography>
              </CardContent>
            </Card>
          </Grid>

            {adherence.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>
                  Adherence 
                </Typography>
                <Typography>
                  Average 
                  (Overall: { !!averageData?.adherence_overall ? averageData?.adherence_overall?.toFixed(2) : 'N/A' })
                  (Last 7 days: { !!averageData?.adherence_last_7_days ? averageData?.adherence_last_7_days?.toFixed(2) : 'N/A' })
                </Typography>
                <div style={{ width: '720px', overflowX: 'scroll', height: 270 }}>
                  <BarChart
                    width={adherence.length >= 14 ? adherence.length * 50 : 700}
                    height={250}
                    data={
                      adherence
                        ? adherence.map((step: any) => ({
                          date: dayjs(step.updated_at).format("MM-DD"),
                          Percentage: !!step?.todo_completion_percentage ? parseInt(step.todo_completion_percentage) : 0
                        }))
                        : [{ name: "uv", Percentage: 0 }]
                    }
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis style={{ fontFamily: font.regular }} dataKey="date" />
                    <YAxis style={{ fontFamily: font.regular }} label={{ value: 'Percentage Complete', angle: -90, position: 'insideBottomLeft', fontFamily: font.regular }} />
                    <Tooltip />
                    <Bar dataKey="Percentage" fill='#AAD400' />
                  </BarChart>
                </div>
              </div>
            )}

            {userMeasurements.flexion && (
              <div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography>Flexion</Typography>
                  <Typography>
                    Average 
                    (Overall: { !!averageData?.flexion_overall ? averageData?.flexion_overall?.toFixed(2) : 'N/A' })
                    (Last 7 days: { !!averageData?.flexion_last_7_days ? averageData?.flexion_last_7_days?.toFixed(2) : 'N/A' })
                  </Typography>
                  <div style={{ width: '720px', overflowX: 'scroll', height: 300 }}>
                    <BarChart
                      width={userMeasurements.flexion.length >= 14 ? userMeasurements.flexion.length * 50 : 700}
                      height={250}
                      data={
                        userMeasurements.flexion
                          ? userMeasurements.flexion.map((measurement: any) => ({
                            date: dayjs(measurement.created_at).format("MM-DD"),
                            Degree: measurement.measurement,
                          }))
                          : [{ name: "uv", Degree: 0 }]
                      }
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis style={{ fontFamily: font.regular }} dataKey="date" />
                      <YAxis style={{ fontFamily: font.regular }} label={{ value: 'Measurement (degree)', angle: -90, position: 'insideBottomLeft', fontFamily: font.regular }} />
                      <Tooltip />
                      <Bar dataKey="Degree" fill="#FBB03B" />
                    </BarChart>
                  </div>
                </div>
              </div>
            )}

            {userMeasurements.extension && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>Extension</Typography>
                <Typography>
                    Average 
                    (Overall: { !!averageData?.extension_overall ? averageData?.extension_overall?.toFixed(2) : 'N/A' })
                    (Last 7 days: { !!averageData?.extension_last_7_days ? averageData?.extension_last_7_days?.toFixed(2) : 'N/A' })
                  </Typography>
                <div style={{ width: '720px', overflowX: 'scroll', height: 300 }}>
                  <BarChart
                    width={userMeasurements.extension.length >= 14 ? userMeasurements.extension.length * 50 : 700}
                    height={250}
                    data={
                      userMeasurements.extension
                        ? userMeasurements.extension.map((measurement: any) => ({
                          date: dayjs(measurement.created_at).format("MM-DD"),
                          Degree: measurement.measurement,
                        }))
                        : [{ name: "uv", Degree: 0 }]
                    }
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" style={{ fontFamily: font.regular }} />
                    <YAxis style={{ fontFamily: font.regular }} label={{ value: 'Measurement (degree)', angle: -90, position: 'insideBottomLeft', fontFamily: font.regular }} />
                    <Tooltip />
                    <Bar dataKey="Degree" fill="#6BBCD9" />
                  </BarChart>
                </div>

              </div>
            )}

          <Grid container spacing={2}>
            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '50px' }}>
              <Typography style={{ fontWeight: 800 }}>
                Subscriptions
              </Typography>
              <List>
                {subscriptions.length > 0 ? (
                  subscriptions.map((subscription: any, index: number) => (
                    <React.Fragment key={uuidv4()}>
                    <ListItem alignItems="flex-start">
                      <ListItemText 
                        primary={
                          <React.Fragment>
                            {index + 1}. | {subscription.product_id}
                            <IconButton onClick={() => handleClickOpen(subscription)} size="small">
                              <InfoIcon />
                            </IconButton>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            {/* <Box component="span" display="block">
                              Created At: {subscription.created_at ? dayjs(subscription.created_at).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                            </Box> */}
                            <Box component="span" display="block">
                              Transaction At: {!!subscription.transaction_date ? dayjs(new Date(parseInt(subscription.transaction_date))).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                            </Box>
                            <Box component="span" display="block">
                              Type: {subscription.subscription_type}
                            </Box>
                            <Box component="span" display="block">
                              Currency: {subscription.currency}
                            </Box>
                            <Box component="span" display="block">
                              Price: {subscription.price}
                            </Box>
                          </React.Fragment>
                        } 
                      />
                    </ListItem>
                    <Divider component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <Typography>
                    This user has no subscriptions
                  </Typography>
                )
                }
              </List>
            </Grid>

            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '50px' }}>
              <Typography style={{ fontWeight: 800 }}>
                Subscription Transactions
              </Typography>
              <List>
                {subscriptionTransactions.length > 0 ? (
                  subscriptionTransactions.map((subscriptionTransaction: any, index: number) => (
                    <React.Fragment key={uuidv4()}>
                      <ListItem alignItems="flex-start">
                        <ListItemText 
                          primary={
                            <React.Fragment>
                              {index + 1}. | {subscriptionTransaction.product_id}
                              <IconButton onClick={() => handleClickOpen(subscriptionTransaction)} size="small">
                                <InfoIcon />
                              </IconButton>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Box component="span" display="block">
                                Transaction Id: { !!subscriptionTransaction.transaction_id ? subscriptionTransaction.transaction_id : 'N/A'}
                              </Box>
                              <Box component="span" display="block">
                                Purchase At: {subscriptionTransaction.purchase_date ? dayjs(new Date(parseInt(subscriptionTransaction.purchase_date) * 1000)).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                              </Box>
                              <Box component="span" display="block">
                                Start At: {subscriptionTransaction.start_time ? dayjs(new Date(parseInt(subscriptionTransaction.start_time))).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                              </Box>
                              <Box component="span" display="block">
                                End At: {subscriptionTransaction.end_time ? dayjs(new Date(parseInt(subscriptionTransaction.end_time))).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                              </Box>
                              <Box component="span" display="block">
                                Device: {subscriptionTransaction.device_type}
                              </Box>
                            </React.Fragment>
                          } 
                        />
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <Typography>
                    This user has no subscription transactions
                  </Typography>
                )
                }
              </List>
            </Grid>

            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '50px' }}>
              <Typography style={{ fontWeight: 800 }}>
                Revenuecat Subscription Transactions
              </Typography>
              <List>
                {revenuecatSubscriptionTransactions.length > 0 ? (
                  revenuecatSubscriptionTransactions.map((subscriptionTransaction: any, index: number) => (
                    <React.Fragment key={uuidv4()}>
                      <ListItem alignItems="flex-start">
                        <ListItemText 
                          primary={
                            <React.Fragment>
                              {index + 1}. | {subscriptionTransaction.product_id}
                              <IconButton onClick={() => handleClickOpen(subscriptionTransaction)} size="small">
                                <InfoIcon />
                              </IconButton>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Box component="span" display="block">
                                Transaction Id: { !!subscriptionTransaction.transaction_id ? subscriptionTransaction.transaction_id : 'N/A'}
                              </Box>
                              <Box component="span" display="block">
                                Start At: {subscriptionTransaction.purchase_date ? dayjs(new Date(parseInt(subscriptionTransaction.purchase_date))).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                              </Box>
                              <Box component="span" display="block">
                                End At: {subscriptionTransaction.expiration_date ? dayjs(new Date(parseInt(subscriptionTransaction.expiration_date))).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                              </Box>
                              <Box component="span" display="block">
                                Store: {subscriptionTransaction.store}
                              </Box>
                            </React.Fragment>
                          } 
                        />
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <Typography>
                    This user has no revenuecat subscription transactions
                  </Typography>
                )
                }
              </List>
            </Grid>
            
          </Grid>

          <Grid container spacing={2}>
            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '50px' }}>
              <Typography>
                <strong>First Stage:</strong> { !!firstStage ? firstStage : 'N/A'}
              </Typography>

              <Typography>
                <strong>Current Stage:</strong> { !!currentStage ? currentStage : 'N/A'}
              </Typography>

              <Typography>
                <i>See all stages</i>
                <IconButton onClick={handleClickStageCollapse} size="small">
                  {openStage ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Typography>
              <Collapse in={openStage} timeout="auto" unmountOnExit>
                <List>
                  { stageList && stageList.length > 0 ? (
                    stageList.map((stage: any, index: number) => (
                      <React.Fragment key={uuidv4()}>
                        <ListItem 
                          alignItems="flex-start"
                          sx={{
                            backgroundColor: !!stage?.is_current_stage ? '#AAD400' : 'inherit',
                            borderRadius: '5px',
                          }}
                          >
                          <ListItemText color="textPrimary"
                            primary={
                              <React.Fragment>

                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {stage.stage_name} | ({ !!stage?.todo_completion_percentage ? parseInt(stage.todo_completion_percentage) : 0 }%)
                                </Typography>
                                <IconButton onClick={() => handleClickOpen(stage)} size="small">
                                  <InfoIcon />
                                </IconButton>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Box component="span" display="block">
                                  Status: {stage.status}
                                </Box>
                                { stage.started_at ? (
                                  <Box component="span" display="block">
                                    Started at: {stage.started_at ? dayjs(stage.started_at).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                                  </Box>
                                ) : null }

                                { stage.completed_at ? (
                                  <Box component="span" display="block">
                                    Completed at: {stage.completed_at ? dayjs(stage.completed_at).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                                  </Box>
                                ) : null }

                                { stage.skipped_at ? (
                                  <Box component="span" display="block">
                                    Skipped at: {stage.skipped_at ? dayjs(stage.skipped_at).format('MMM DD, YYYY (HH:mm)') : 'N/A'}
                                  </Box>
                                ) : null }

                                <Box component="span" display="block">
                                  Week No.: {stage.week_number}
                                </Box>

                                <Box component="span" display="block">
                                  Current Stage: {!!stage?.is_current_stage ? 'Yes' : 'No'}
                                </Box>
                              </React.Fragment>
                            } 
                          />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography>
                      This user has no current stages
                    </Typography>
                  )
                  }
                </List>
              </Collapse>
            </Grid>

          </Grid>

          <Grid container spacing={2} style={{ marginTop: '50px'}}>
            <Grid lg={6} md={6} xl={6}>
              <Typography style={{ textAlign: 'center', marginTop: '30px', marginBottom: '20px' }}>Notes</Typography>
                <TextEditor
                  customerId={id}
                  setNotes={setNotes}
                  notes={notes}
                />
            </Grid>

            <Grid lg={6} md={6} xl={6}>
                {notes.map((note: any) => (
                  <div key={uuidv4()} style={{ border: '1px solid slategrey', padding: '15px', borderRadius: '5px', marginBottom: '10px' }}>
                    <Typography>{note.body}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography>Created At: { note.created_at ? dayjs(note.created_at).format('MMM DD, YYYY (HH:mm)') : 'N/A' }</Typography>
                      <Typography style={{ marginLeft: '20px' }}>By: Nirtal Shah - id {note.id}</Typography>
                      <Button onClick={() => handleDeleteNote(note.id)} style={{ marginLeft: '15px' }} variant="contained" color="error">Delete</Button>
                    </div>
                  </div>
                ))}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: '50px'}}>
            <Grid lg={6} md={6} xl={6}>
              <Button onClick={() => showDeleteModal()} variant="contained" color="error" size="large" style={{ marginLeft: '50px'}}>
                DELETE THIS USER
              </Button>
            </Grid>
          </Grid>

          {/* <div>Assign a Physiotherapist:</div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Physio</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPhysio}
                label="Physio"
                onChange={handlePhysioChange}
              >
                {physios.map((physio: any) => (
                  <MenuItem value={`${physio.given_name} ${physio.family_name}`}>{physio.given_name} {physio.family_name}</MenuItem>
                ))}
              </Select>
            </FormControl> */
          }
        </>
      }
    </>
  )
}
